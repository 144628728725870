import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />

    <section>
        <div class="section-header">
            <div class="container">
                <h2>About</h2>
            </div>
        </div>
        <div class="section-body">
            <div class="container">
                <div class="row">
                    <div class="columns nine">
                        <div dangerouslySetInnerHTML={{ __html: data.platform.allHomePages.edges[0].node.aboutSection }} />
                    </div>
                    <div class="columns three">
                        <img
                            class="profile-image"
                            src={data.platform.allHomePages.edges[0].node.profilePhoto.url}
                            alt={data.platform.allHomePages.edges[0].node.profilePhoto.description}
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <section>
        <div class="section-header">
            <div class="container">
                <h2>Recordings</h2>
            </div>
        </div>
        <div class="section-body">
            <div class="container">
                <p>A recording of Anna's radio appearence as available at <a href="https://soundcloud.com/davidgrubb/sets/high-rise-live-on-radio">https://soundcloud.com/davidgrubb/sets/high-rise-live-on-radio</a></p>
                <iframe width="100%" height="450" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/54635367&amp;color=a162ba&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false"></iframe>
            </div>
        </div>
    </section> */}
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    platform {
        allHomePages (first: 1) {
            edges {
                node {
                    aboutSection
                    profilePhoto {
                        url (transforms: {
                            width: 220,
                            height: 220
                        })
                        description
                    }
                }
            }
        }
    }
  }
`;
